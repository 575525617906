import { urlRs } from "~/utils/url";
import { createSignal, onCleanup, onMount, Show } from "solid-js";
import { isServer } from "solid-js/web";
import Anchors from "~/components/Program/Components/Anchors";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./StackBar.css";

type StackBarProps = {
  wrapper: ProgramWrapper;
};

export default function StackBar(props: StackBarProps) {
  const [, { sendShowEvent, setTempZone }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [programHeaderIsStacked, setProgramHeaderIsStacked] =
    createSignal(false);
  const [, { sendClick }] = useEventsContext();
  const settings = useDrupalSettingsContext();

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    const currentScrollTop = document.documentElement.scrollTop;
    if (currentScrollTop >= window.innerHeight - 100) {
      setProgramHeaderIsStacked(true);
    } else {
      setProgramHeaderIsStacked(false);
    }
  };

  return (
    <>
      <div
        class="program-stackbar"
        classList={{
          "with-offer": props.wrapper.program.animation !== null,
          stacked: programHeaderIsStacked(),
        }}
        data-ga-zone="header-sticky"
        data-test="header-sticky"
      >
        <Show when={!settings.switch_logos}>
          <a href={urlRs("front", "/")} title="Accueil" class="logo">
            <img
              src="/images/logo-cogedim.svg"
              alt="Cogedim"
              height="40"
              width="40"
            />{" "}
            Cogedim
          </a>
        </Show>

        <div class="flex-line" data-test="summary">
          <div class="title-group">
            <address>
              <Show
                fallback={
                  <a href={props.wrapper.program.field_geography.path?.alias}>
                    {props.wrapper.program.field_city} -{" "}
                    {props.wrapper.program.field_postal_code?.substring(0, 2)}
                  </a>
                }
                when={
                  props.wrapper.program.field_address_hide &&
                  props.wrapper.program.field_address_override
                }
              >
                <span>{props.wrapper.program.field_address_override}</span>
              </Show>
            </address>
            <p class="h1">{props.wrapper.program.title}</p>
          </div>

          <Anchors wrapper={props.wrapper} />

          <div class="ctas">
            <Show when={props.wrapper.program.field_leaflet?.uri?.url}>
              <Show
                when={settings.form_leaflet_bypass}
                fallback={
                  <button
                    type="button"
                    class="btn"
                    data-test="cta-leaflet"
                    onClick={(e) => {
                      sendShowEvent(`leaflet`, e, {
                        nid: props.wrapper.program.drupal_internal__nid,
                      });
                      openModalForm!("leaflet", { wrapper: props.wrapper });
                      setModalFormHeadline!("Télécharger la brochure");
                    }}
                  >
                    Télécharger la brochure
                  </button>
                }
              >
                <a
                  href={props.wrapper.program.field_leaflet?.uri?.url}
                  class="btn"
                  data-test="btn-leaflet"
                  target="_blank"
                  onClick={() => {
                    sendClick(`btn-leaflet`, "header-sticky");
                  }}
                  rel="noindex nofollow"
                >
                  Télécharger la brochure
                </a>
              </Show>
            </Show>
            <button
              type="button"
              class="btn"
              data-test="cta-advisor"
              onClick={(e) => {
                setTempZone(e);
                openModalForm!("advisor", { wrapper: props.wrapper });
                setModalFormHeadline!("Contacter un conseiller");
              }}
            >
              Contacter un conseiller
            </button>
          </div>
        </div>

        <Show when={props.wrapper.program.animation}>
          <div class="exclusive-offer" data-test="offer">
            <span>Offre exclusive</span>{" "}
            <a href="#exclusive-offer">Découvrir</a>
          </div>
        </Show>
      </div>
    </>
  );
}
