import FirstScreenMainImage from "~/components/Program/Components/FirstScreenMainImage";
import {
  Show,
  createResource,
  createEffect,
  createSignal,
  Suspense,
} from "solid-js";
import ParagraphProgramPush from "~/components/Program/Components/ParagraphProgramPush";
import * as helper from "~/utils/helper_program";
import MediaContainer from "~/components/Program/Components/MediaContainer";
import { hasNoEscape } from "~/utils/no_escape";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { getProgramViewCount } from "~/lib/fetcher";
import {
  getPricesSummary,
  getTypologiesSummary,
  isOutOfStock,
} from "~/utils/helper_program";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import BrandProgram from "~/components/shared/BrandProgram";

import IconPlayCircle from "~/img/icons/play_circle.svg";
import IconArrowBack from "~/img/icons/arrow_back.svg";
import IconChevronLeft from "~/img/icons/chevron_left.svg";
import IconChevronRight from "~/img/icons/chevron_right.svg";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./FirstScreen.css";

type FirstScreenProps = {
  wrapper: ProgramWrapper;
};

export default function FirstScreen(props: FirstScreenProps) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <section
        class="full-visual"
        classList={{
          "no-escape": hasNoEscape(),
          "switch-logos": settingsContext.switch_logos,
        }}
        data-ga-zone="first-screen"
        data-test="first-screen"
      >
        <div class="visual">
          <Show
            when={
              !isOutOfStock(props.wrapper) &&
              props.wrapper.program.field_push_top
            }
          >
            <div data-test="push-top">
              <ParagraphProgramPush
                wrapper={props.wrapper}
                push={props.wrapper.program.field_push_top}
              />
            </div>
          </Show>

          <div class="frame">
            <FirstScreenMainImage
              imageDesktop={props.wrapper.program.field_image_desktop}
            />
            <Show when={hasNoEscape()}>
              <Show
                when={settingsContext.switch_logos}
                fallback={
                  <span class="cog-logo">
                    <img
                      src="/images/logo-cogedim.svg"
                      alt="Logo Cogedim"
                      height="40"
                      width="40"
                    />
                    Cogedim
                  </span>
                }
              >
                <span
                  class="cog-logo"
                  innerHTML={settingsContext.logo_primary}
                />
              </Show>
            </Show>

            <Show when={!isOutOfStock(props.wrapper)}>
              <Show
                when={props.wrapper.program.field_video_first_screen}
                fallback={
                  <Show
                    when={
                      props.wrapper.program.field_image_desktop &&
                      props.wrapper.program.field_image_desktop!.length > 0
                    }
                  >
                    <button
                      type="button"
                      onClick={() => {
                        sendClick("main-image", "first-screen");
                        setMediaOverlay(
                          "images",
                          props.wrapper.program.field_image_desktop,
                        );
                      }}
                      class="direct-open-gallery"
                      aria-label="Afficher la galerie"
                      data-test="btn-gallery"
                    >
                      <div class="content-part">
                        <i aria-hidden="true" class="cog-icon left">
                          <IconChevronLeft />
                        </i>
                        <i aria-hidden="true" class="cog-icon right">
                          <IconChevronRight />
                        </i>
                      </div>
                    </button>
                  </Show>
                }
              >
                <button
                  type="button"
                  onClick={() => {
                    sendClick("btn-videos", "first-screen");
                    setMediaOverlay("video", props.wrapper.program.field_video);
                  }}
                  class="direct-open-gallery video"
                  data-test="btn-video"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconPlayCircle />
                  </i>
                  <span>Lancer la vidéo</span>
                </button>
              </Show>
            </Show>

            <div class="tools">
              <Show when={!hasNoEscape()}>
                <button
                  type="button"
                  class="btn btn-icon back-link"
                  onClick={() => window.history.back()}
                  aria-label="Retour"
                  data-test="btn-back"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowBack />
                  </i>
                </button>
              </Show>
            </div>
          </div>
        </div>

        <div class="info-box">
          <div id="info-box-content" class="content-part info-box-content">
            <Show
              when={isOutOfStock(props.wrapper)}
              fallback={<ProgramStdContent wrapper={props.wrapper} />}
            >
              <div class="program-header" data-test="header">
                <CommonContent wrapper={props.wrapper} />
                <p class="out-of-stock-text">
                  Il n'y a plus de logement disponible.
                </p>
              </div>
            </Show>
          </div>
        </div>
      </section>
    </>
  );
}

function ProgramStdContent(props: { wrapper: ProgramWrapper }) {
  const [, { sendShowEvent, setTempZone }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { sendClick }] = useEventsContext();
  const settings = useDrupalSettingsContext();

  return (
    <>
      <div class="program-header" data-test="header">
        <CommonContent wrapper={props.wrapper} />

        <Show when={!helper.isPreview(props.wrapper)}>
          <a href="#prix-plans" class="summary" data-test="summary">
            <span innerHTML={getTypologiesSummary(props.wrapper)} />{" "}
            <span class="price" innerHTML={getPricesSummary(props.wrapper)} />{" "}
            <span class="anchor">Prix et plans</span>
          </a>
        </Show>

        <div class="ctas">
          <Show when={props.wrapper.program.field_leaflet?.uri?.url}>
            <Show
              when={settings.form_leaflet_bypass}
              fallback={
                <button
                  type="button"
                  class="btn"
                  data-test="cta-leaflet"
                  onClick={(e) => {
                    sendShowEvent(`leaflet`, e, {
                      nid: props.wrapper.program.drupal_internal__nid,
                    });
                    openModalForm!("leaflet", { wrapper: props.wrapper });
                    setModalFormHeadline!("Télécharger la brochure");
                  }}
                >
                  Télécharger la brochure
                </button>
              }
            >
              <a
                href={props.wrapper.program.field_leaflet?.uri?.url}
                class="btn"
                data-test="btn-leaflet"
                target="_blank"
                onClick={() => {
                  sendClick(`btn-leaflet`, "first-screen");
                }}
                rel="noindex nofollow"
              >
                Télécharger la brochure
              </a>
            </Show>
          </Show>
          <button
            type="button"
            class="btn"
            data-test="cta-advisor"
            onClick={(e) => {
              setTempZone(e);
              openModalForm!("advisor", { wrapper: props.wrapper });
              setModalFormHeadline!("Contacter un conseiller");
            }}
          >
            Contacter un conseiller
          </button>
        </div>

        <MediaContainer wrapper={props.wrapper} />
      </div>
    </>
  );
}

function CommonContent(props: { wrapper: ProgramWrapper }) {
  const [id, setId] = createSignal<number>(
    // eslint-disable-next-line solid/reactivity
    props.wrapper.program.drupal_internal__nid,
  );

  const [viewCount] = createResource(id, getProgramViewCount, {
    deferStream: false,
    ssrLoadFrom: "server",
  });

  createEffect(() => {
    setId(props.wrapper.program.drupal_internal__nid);
  });

  return (
    <>
      <BrandProgram brand={props.wrapper.program.field_brand} isSmallVersion />

      <Show when={!isOutOfStock(props.wrapper)}>
        <Show
          when={props.wrapper.program.animation}
          fallback={
            <Suspense>
              <Show when={viewCount()}>
                <div class="view-count" data-test="counter">
                  {viewCount()} visiteurs intéressés
                </div>
              </Show>
            </Suspense>
          }
        >
          <a href="#exclusive-offer" class="exclusive-offer" data-test="offer">
            Offre exclusive
          </a>
        </Show>
      </Show>

      <div class="row-address">
        <address itemscope itemtype="https://schema.org/PostalAddress">
          <Show
            fallback={
              <Show
                fallback={
                  <a href={props.wrapper.program.field_geography?.path?.alias}>
                    <span itemProp="addressLocality">
                      {props.wrapper.program.field_city}
                    </span>{" "}
                    -{" "}
                    <span itemProp="postalCode">
                      {props.wrapper.program.field_postal_code?.substring(0, 2)}
                    </span>
                  </a>
                }
                when={hasNoEscape()}
              >
                <span itemprop="addressLocality">
                  {props.wrapper.program.field_city}
                </span>{" "}
                -{" "}
                <span itemprop="postalCode">
                  {props.wrapper.program.field_postal_code?.substring(0, 2)}
                </span>
              </Show>
            }
            when={props.wrapper.program.field_address_hide}
          >
            <Show when={props.wrapper.program.field_address_override}>
              <span itemProp="addressLocality">
                {props.wrapper.program.field_address_override}
              </span>
            </Show>
          </Show>
        </address>
      </div>

      <h1>{props.wrapper.program.title}</h1>
    </>
  );
}
